import { createApp } from 'vue';
import App from './app.vue';
import './common/common.less';
import vuetify from './plugins/vuetify';
import router from './router';
import base64js from 'base64-js';
window.base64js = base64js;
window.Zlib = require('zlibjs/bin/zlib.min.js').Zlib;

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.mount('#app');
